





























import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import ChatButtonProxy from '@/components/ChatButtonProxy.vue'
import firebaseService from '@/services/firebase-service'
import lptagService from '@/services/lptag-service'

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const BJNEmbedSDK = require('bluejeans-webrtc-embed-sdk')

@Component({
  components: {
    ContentArea,
    Title,
    ProgressLine
  }
})
export default class TestInProgress extends Vue {
  private sentData = false
  $refs!: {
    btn: ChatButtonProxy
  }

  async mounted (): Promise<void> {
    if (window.innerWidth < 767) {
      this.$root.$emit('logo', true)
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
    this.$root.$emit('open-chat')
    await firebaseService.databaseConnection().then((db) => {
      const ctu = localStorage.getItem('ctu') as string
      return db.ref('qvue_travel_temp/' + ctu).on('value', (snapshot:any) => {
        console.log('ctu ovserver', ctu)
        const data = snapshot.val()
        const locale = localStorage.getItem('locale')
        // if (Object.keys(data).length !== 0) {
        if (data !== undefined) {
          if (data === 'test_result=2' || data === 'test_result=1') {
            this.$store.dispatch('walkthrough/completeTest', data)
            localStorage.setItem('testResult', btoa(data))
            this.$router.push('/' + locale + '/outcome/2')
          } else if (data === 'test_result=3') {
            this.$store.dispatch('walkthrough/completeTest', data)
            localStorage.setItem('testResult', btoa(data))
            this.$router.push('/' + locale + '/outcome/1')
          } else if (data === 'test_result=4' || data === 'test_result=5') {
            this.$store.dispatch('walkthrough/completeTest', data)
            localStorage.setItem('testResult', btoa(data))
            this.$router.push('/' + locale + '/outcome/3')
          } else {
            // this.$store.dispatch('walkthrough/completeTest', data)
            // this.$router.push('/outcome/3')
          }
        }
        // }
        // return data
      })
    })
    // const btnmaximize: any = document.querySelector('.lp_close.lpc_maximized-header__close-button.lpc_mobile')
    // if (btnmaximize) {
    //   btnmaximize.click()
    // }
    // setTimeout(() => {
    //   this.$root.$emit('open-chat')
    // }, 1000)

    // BJNEmbedSDK.observe('connectionState', function () {
    //   if (BJNEmbedSDK.connectionState === 'Disconnected') {
    //     const btnmaximize: any = document.querySelector('.lp_close.lpc_maximized-header__close-button.lpc_mobile')
    //     const el: any = document.getElementById('bluejeans_holder')
    //     if (el) {
    //       el.style.display = 'none'
    //     }
    //     if (btnmaximize) {
    //       btnmaximize.click()
    //     }
    //   }
    // })

    // BJNEmbedSDK.observe('participants', function () {
    //   if (BJNEmbedSDK.participants.length === 0 && BJNEmbedSDK.connectionState === 'Disconnected') {
    //     const btnmaximize: any = document.querySelector('.lp_close.lpc_maximized-header__close-button.lpc_mobile')
    //     const el: any = document.getElementById('bluejeans_holder')
    //     if (el) {
    //       el.style.display = 'none'
    //     }
    //     if (btnmaximize) {
    //       btnmaximize.click()
    //     }
    //   }
    // })
  }

  // private callAction (): void {
  //   const el = document.getElementById('apply-btn')
  //   const wrapper = document.getElementById('join-video-call')
  //   if (el && wrapper) {
  //     const meetId = el.getAttribute('meet-id')
  //     if (meetId) {
  //       wrapper.style.display = 'none'
  //       this.joinCall(meetId)
  //     }
  //   }
  // }

  // private joinCall (meetId: string): void {
  //   var userInfo = this.$store.getters['walkthrough/userInfo']
  //   BJNEmbedSDK.setName(userInfo.fullName)
  //   BJNEmbedSDK.joinMeeting({
  //     meetingInfo: {
  //       meetingId: meetId,
  //       name: userInfo.fullName
  //     },
  //     iFrameProps: {
  //       selectorId: '#bluejeans_holder'
  //     },
  //     uiProps: {
  //       customBackground: '#211266',
  //       hideFooter: true,
  //       hideChatPanel: true,
  //       hideAppPitches: true,
  //       hideCopyLink: true,
  //       hideRatingScreen: true,
  //       hideRoomJoinOption: true,
  //       hideSignInButton: true,
  //       lockMeetingControls: true
  //     }
  //   })

  //   const el: any = document.getElementById('bluejeans_holder')
  //   const audio:any = document.getElementById('audio-ring')
  //   const logo:any = document.getElementsByClassName('kxKPvR')
  //   // setTimeout(() => {

  //   if (logo) {
  //     logo.style.display = 'none  !important'
  //   }
  //   // }, 1000)
  //   // audio.muted = false
  //   // audio.pause()
  //   if (el) {
  //     el.style.height = '100%'
  //     if (window.innerWidth < 767) {
  //       el.style.zIndex = '999999999999999999999999999999'
  //     }
  //   }
  // }

  private getName (): string {
    if (this.$store.state?.auth?.userSessionData?.fullName) return this.$store.state.auth.userSessionData.fullName
    else return ''
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private openChat (): void {
    this.$root.$emit('open-chat')
  }

  async created (): Promise<void> {
    localStorage.setItem('prevRoute', this.$route.path as string)
    this.triggerEvent()
    await firebaseService.databaseConnection().then((db) => {
      const ctu = localStorage.getItem('ctu') as string
      db.ref('qvue_travel_temp/' + ctu).off('value')
    })
  }

  public triggerEvent (): void {
    const a = setInterval(async () => {
      const convId = localStorage.getItem('convId') as string
      if (!this.sentData && convId !== null && convId !== undefined && convId !== '') {
        clearInterval(a)
        this.sentData = true
        const getBotDetails = await lptagService.getBotDetails(convId)
        console.log('QVUE: API 1 calling', getBotDetails)

        if (getBotDetails?.userId !== undefined && getBotDetails?.botId !== undefined && getBotDetails?.conversationId !== undefined) {
          const sentData = {
            botId: getBotDetails?.botId,
            conversationId: convId,
            userId: getBotDetails?.userId,
            message: 'App initiating proctoring',
            contextVariables: [{ name: 'appProctorStart', value: true }, { name: 'appFAQStart', value: false }, { name: 'appConvId', value: convId }]
          }
          console.log('QVUE: API 2 calling', sentData)
          await lptagService.postPageInfo(sentData)
        }
      }
    }, 1000)
  }

  private destroyed (): void {
    if (window.innerWidth < 767) this.$root.$emit('logo', false)
  }
}
